import React, { FC } from "react";
import {
  AlertNotification,
  Button,
  H3,
  Popup,
  PopupHeader,
  SkubotSpinner,
  XIcon,
} from "@commonsku/styles";
import { BASE_ZINDEX } from "../../../popup-factory";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { closePopup } from "../../../actions/popup";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import {
  selectIsLoading,
  setIsLoadingFalse,
  setIsLoadingTrue,
} from "../../../redux/isLoading";
import { createDeleteTemplate } from "../../../actions/shop";

interface DeleteTemplatePopupProps {
  public_view_template_id: string;
  public_view_template_title: string;
}

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const DeleteTemplatePopup: FC<DeleteTemplatePopupProps> = ({
  public_view_template_id,
  public_view_template_title,
}) => {
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch<ThunkDispatch<any, any, AnyAction>>();

  const onPopupClose = () => dispatch(closePopup());

  const handleSaveTemplate = async () => {
    dispatch(setIsLoadingTrue());
    await dispatch(createDeleteTemplate(public_view_template_id));
    dispatch(setIsLoadingFalse());
    dispatch(closePopup());
  };

  return (
    <Popup
      overlayZIndex={BASE_ZINDEX + 100}
      zIndex={BASE_ZINDEX + 200}
      style={{ width: 560, height: 224, borderRadius: 10 }}
      header={
        <PopupHeader
          style={{ padding: 0, alignItems: "center", marginBottom: 16 }}
        >
          <H3 style={{ margin: "0px" }} className="title">
            Are you sure?
          </H3>
          <XIcon
            style={{ cursor: "pointer" }}
            size="large"
            onClick={() => onPopupClose()}
          />
        </PopupHeader>
      }
    >
      <StyledContainer>
        <AlertNotification
          alertType="error"
          style={{ height: 80, overflowY: "auto" }}
        >
          <span>
            You are about to delete the template{" "}
            <strong>{public_view_template_title}</strong>.
            <strong style={{ display: "block" }}>
              You cannot undo this action!
            </strong>
          </span>
        </AlertNotification>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            gap: 16,
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <Button
            variant="secondary"
            onClick={onPopupClose}
            size="medium"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant={"error"}
            onClick={handleSaveTemplate}
            size="medium"
            style={{ width: 135 }}
            disabled={isLoading}
          >
            {isLoading ? (
              <SkubotSpinner
                size="button"
                skubot={false}
                style={{ margin: "auto" }}
              />
            ) : (
              "Delete"
            )}
          </Button>
        </div>
      </StyledContainer>
    </Popup>
  );
};

export default DeleteTemplatePopup;
